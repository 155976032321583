import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import BookAppointment from "../BookAppointment";

import "./servicesEMMIM.scss";
const ServicesEMMIM = ({ name, content }) => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(isFrontPage: { eq: true }) {
          id
          layout {
            ctalabel
            serviceid1
            serviceid2
            serviceid3

            servicetitle1
            servicetitle2
            servicetitle3
            servicecontent1
            servicecontent2
            servicecontent3
            service1image {
              title
              caption
              description
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
            service2image {
              title
              caption
              description
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
            service3image {
              title
              caption
              description
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const layout = data.wpPage.layout;

  var keys = Object.keys(layout).filter(
    (e,i) => layout[e] && e.indexOf("image") > -1
  ); 
  
  return (
    <section className="servicesEMMIM-section pb-5" id={name}>
      <div className="container-fluid content pt-5 gray-100-bg noGutters">

        {keys.map((c, idx) => {
          let serviceContent = layout[`servicecontent${idx + 1}`].split(
            "[CTA]"
          );

          return (
            <Fade
              left={(idx + 1) % 2 === 0}
              right={idx % 2 === 0}
              distance="100px"
            >
              <div
                className={`row singleServiceContainer justify-content-md-center pb-3 ${
                  idx !== 0 ? "pt-5" : ""
                } text-center ${idx % 2 === 0 ? "" : "rowEven"}`}
                id={layout[`serviceid${idx + 1}`]}
              >
                <div
                  className={` col-sm-12 col-md-${idx === 0 ? 6 : 6} order-md-${
                    idx % 2
                  }`}
                >
                  <BackgroundImage
                    alt={`${layout[`servicetitle${idx + 1}`]}`}
                    Tag="div"
                    style={{
                      backgroundPosition: "center top",
                      backgroundSize: "75% auto",
                      //backgroundAttachment: "fixed",
                      minHeight: "400px",
                    }}
                    className={`bgImageFixed`}
                    fluid={layout[c].localFile.childImageSharp.fluid}
                  />
                </div>

                <div
                  className={`col-sm-12 col-md-${
                    idx === 0 ? "12 pb-5" : 6
                  } order-md-${(idx + 1) % 2} text-left  pl-${
                    (idx + 1) % 2 && idx > 0 ? 0 : 5
                  } pr-${(idx + 1) % 2 || idx == 0 ? 5 : 0}`}
                >
                  <div
                    className={`contentText pl-${(idx + 1) % 2 ? 0 : 2} pr-${
                      (idx + 1) % 2 ? 2 : 0
                    } centerMobile`}
                  >
                    {serviceContent.map((txt, idx) => (
                      <>
                        <div
                          key={"sscc" + idx}
                          dangerouslySetInnerHTML={{
                            __html: txt,
                          }}
                        />
                        {serviceContent.length > 1 && txt.length > 10 && (
                          <div className="m-2 text-center">
                            <BookAppointment
                              title={layout.ctalabel}
                              type={"secondary-bg"}
                            />
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    </section>
  );
};



export default ServicesEMMIM;
