import React from "react";
import BackgroundImage from "gatsby-background-image";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import ServicesEMMIM from "../components/ServicesEMMIM";
import ContactUs from "../components/ContactUs";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../components/Header";
import Loadable from "@loadable/component";
import BookAppointment from "../components/BookAppointment";
import "../assets/sass/services.scss";

const Footer = Loadable(() => import("../components/Footer/index.js"));

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(isFrontPage: { eq: true }) {
          id
          layout {
            ctalabel
            servicesherocontent
            servicesintro
            servicestitle
            servicesheroimage {
              title
              caption
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const {
    ctalabel,
    servicesheroimage,
    servicesherocontent,
    servicestitle,
 servicesintro } = data.wpPage.layout;
  return (
    <Layout>
      <SEO />
      <Header frontPage={true} selected="paraTi" />
      <header className={`servicesHeader`}>
        <BackgroundImage
          className={" bgImageFixed  heroBg"}
          fluid={servicesheroimage.localFile.childImageSharp.fluid}
          style={{
            backgroundPosition: "right top",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundOrigin: "content-box",
          }}
        >
          <div className="gradient ">
            <div
              className={`container-fluid no-gutters pl-0 pr-0 h-100 servicesContainer`}
            >
              <div className="row no-gutters h-100">
                <div className="col-md-1 col-sm-hidden"></div>
                <div className="col-sm-12 col-md-5 align-self-center">
                  <Zoom cascade>
                    <h6
                      className="subtitle fg-text-color"
                      dangerouslySetInnerHTML={{
                        __html: servicesheroimage.caption,
                      }}
                    ></h6>
                    <h1
                      className="title mt-5 fg-text-color"
                      dangerouslySetInnerHTML={{
                        __html: servicesheroimage.title,
                      }}
                    ></h1>
                  </Zoom>
                  <Fade>
                    <p className=" fg-text-color">{servicesherocontent}</p>
                  </Fade>
                </div>
                <div className={"col-md-6 col-sm-hidden"}></div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </header>
      <section className="services-section ">
        <div className="servicesContainer pt-5 pb-5">
          <div className="container">
            <div className="row justify-content-md-center text-center no-gutters">
              <Fade distance="100px">
                <div className="col-sm-12 col-md-1"></div>
                <div className="col-sm-12 col-md-10">
                  <h1 className={`big primary-text-color sentence`}>
                    "Porque todos nós a determinada altura das nossas vidas
                    precisamos de ajuda"
                  </h1>
                </div>
                <div className="col-sm-12 col-md-1"></div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <ServicesEMMIM />
      <div className="contacts pb-5">
        <h1 className="text-center primary-text-color ">
          <span className="destak">Posso ajudar?</span>
        </h1>
        <ContactUs hideContacts={true} />
      </div>

      <Footer />
    </Layout>
  );
};
export default IndexPage;
